import { IPublicClientApplication } from '@azure/msal-browser';

export default async function redirectAndExecuteMagicLogin(
    instance: IPublicClientApplication,
    idTokenHint: string,
    redirectURL: string,
): Promise<void> {
    // Shared state with the portal-app
    window.sessionStorage.setItem('portal-app-local-redirect-url', redirectURL);

    // This call will redirect the browser, any consecutive code is not guaranteed to be called
    return instance.loginRedirect({
        scopes: ['openid'],
        extraQueryParameters: {
            id_token_hint: idTokenHint,
        },
    });
}

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button, CenteredLoadingSpinner, Infobox, LinkButton } from '@ten-netzkundenportal/ui-components';
import { isAxiosError } from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { confirmEmailUpdate } from './api/confirmEmailUpdate';

type Status = 'initial' | 'loading' | 'success' | 'expired' | 'error';

export const ConfirmEmailChange = (): React.ReactElement => {
    const token = new URLSearchParams(useLocation<{ token: string }>().search).get('token');
    const { instance } = useMsal();

    const [status, setStatus] = useState<Status>('initial');

    useEffect(() => {
        setStatus('loading');
        confirmEmailUpdate(token)
            .then(() => {
                setStatus('success');
            })
            .catch((error) => {
                console.error('Failed to confirm email update', error);
                setStatus(isAxiosError(error) && error.response.status === 410 ? 'expired' : 'error');
            });
    }, [token]);

    switch (status) {
        case 'initial':
        case 'loading':
            return <CenteredLoadingSpinner />;
        case 'success':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse!"
                        normalText="Sie können sich nun mit Ihrer neuen E-Mail-Adresse im Netzkundenportal anmelden."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <AuthenticatedTemplate>
                            <LinkButton href="/" label="Zur Übersicht" />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Button onClick={() => instance.loginRedirect()} label="Zur Anmeldung" />
                        </UnauthenticatedTemplate>
                    </div>
                </div>
            );
        case 'expired':
            return errorContent('Leider ist der Bestätigungslink für Ihre neue E-Mail-Adresse nicht mehr gültig.');
        case 'error':
        default:
            return errorContent('Leider ist ein technischer Fehler aufgetreten.');
    }
};

function errorContent(heading: string) {
    return (
        <div className="grid grid-flow-row justify-center py-10">
            <Infobox boldText={heading} normalText="Bitte führen Sie die Änderung der E-Mail-Adresse erneut aus." />
            <div className="flex justify-center w-auto text-center">
                <LinkButton href="/account/accountverwaltung" label="Zur Änderung" />
            </div>
        </div>
    );
}

import { ConfirmationModal, Trash } from '@ten-netzkundenportal/ui-components';
import React from 'react';

export interface DeleteAccountModalProps {
    onConfirm: () => Promise<void>;
    onCancel: () => Promise<void>;
}

export const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({
    onConfirm,
    onCancel,
}: DeleteAccountModalProps) => (
    <ConfirmationModal onConfirm={onConfirm} onCancel={onCancel}>
        <div className="gap-7 grid p-6">
            <div className="flex justify-center">
                <Trash className="w-12 fill-current text-primary" />
            </div>
            <h2 className="flex justify-center font-bold">Account löschen</h2>
            <div className="text-center grid gap-5">
                <p>Sind Sie sicher, dass Sie Ihren Account unwiderruflich löschen möchten?</p>
                <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
            </div>
        </div>
    </ConfirmationModal>
);

function extractUrlParameters() {
    const queryParams = new URLSearchParams(window.location.search);

    const customerType = queryParams.get('customerType');
    const firstName = queryParams.get('firstName');
    const lastName = queryParams.get('lastName');
    const companyName = queryParams.get('companyName');
    const email = queryParams.get('email');

    return { firstName, lastName, companyName, email, customerType };
}
export default extractUrlParameters;

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@ten-netzkundenportal/ui-auth-utils';

import appConfig from './app.config';

export default async function redirectAndExecuteMagicLogin(idTokenHint: string, redirectURL: string): Promise<void> {
    const msal = new PublicClientApplication(msalConfig(appConfig));

    await msal.initialize();

    // Shared state with the portal-app
    window.sessionStorage.setItem('portal-app-local-redirect-url', redirectURL);

    // This call will redirect the browser, any consecutive code is not guaranteed to be called
    return msal.loginRedirect({
        scopes: ['openid'],
        extraQueryParameters: {
            id_token_hint: idTokenHint,
        },
    });
}

import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import {
    CompanyValues,
    FormValues,
    InstallerValues,
    PrivateCustomerValues,
} from '@ten-netzkundenportal/ui-registration';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { errorBadgeNumberNoCompanyFound, errorCompanyForBadgeNumberAlreadyExists } from '../components/messages';
import { isCustomerType } from '../util/guards';
import SignupForm from './BaseSignupForm';
import { createAccount } from './api';
import extractUrlParameters from './extractUrlParameters';

export default (): React.ReactElement => {
    const history = useHistory();
    const [externalCustomerType, setExternalCustomerType] = React.useState<CustomerType>(undefined);
    const [isEmailInputDisabled, setIsEmailInputDisabled] = React.useState(false);
    const [redirectURL, setRedirectURL] = React.useState<string>();

    const reactHookForm = useForm<FormValues>({
        mode: 'onTouched',
        defaultValues: {
            companyName: '',
        },
    });
    const { setValue } = reactHookForm;

    const { setError, clearErrors } = reactHookForm;

    function handleRedirection() {
        const installerRedirectKey = 'installer-post-signup-redirect-url';
        const generalRedirectKey = 'nkp-post-signup-redirect-url';

        const installerRedirectUrlFromSessionStorage = sessionStorage.getItem(installerRedirectKey);
        const generalRedirectUrlFromSessionStorage = sessionStorage.getItem(generalRedirectKey);

        if (installerRedirectUrlFromSessionStorage) {
            setExternalCustomerType('installer');
            setRedirectURL(installerRedirectUrlFromSessionStorage);
            sessionStorage.removeItem(installerRedirectKey);
        } else if (generalRedirectUrlFromSessionStorage) {
            setRedirectURL(generalRedirectUrlFromSessionStorage);
            sessionStorage.removeItem(generalRedirectKey);
        }
    }

    React.useEffect(() => {
        handleRedirection();
    }, []);

    React.useEffect(() => {
        const params = extractUrlParameters();
        const { customerType, firstName, lastName, companyName, email } = params;

        if (companyName) {
            setValue('companyName', companyName);
        }
        if (firstName) {
            setValue('firstName', firstName);
        }
        if (lastName) {
            setValue('lastName', lastName);
        }
        if (email) {
            setValue('email', email);
            setIsEmailInputDisabled(true);
        }
        if (customerType && isCustomerType(customerType)) {
            setExternalCustomerType(customerType);
        }
    }, [setValue]);

    const submit = (customerType: CustomerType) => async (data: FormValues) => {
        if (customerType === 'private') {
            const { firstName, lastName, email, password } = data as PrivateCustomerValues;

            const registrationResponseStatus = await createAccount(
                {
                    firstName,
                    lastName,
                    email,
                    password,
                    customerType: 'private',
                },
                redirectURL,
            );

            if (registrationResponseStatus === 201) history.push('/registrierung/erfolg');
        } else if (customerType === 'installer') {
            const { companyName, email, password, badgeNumber } = data as InstallerValues;

            const registrationResponseStatus = await createAccount(
                {
                    name: companyName,
                    email,
                    password,
                    customerType,
                    badgeNumber: badgeNumber.toUpperCase(),
                },
                redirectURL,
            );
            if (registrationResponseStatus === 201) {
                clearErrors('badgeNumber');
                history.push('/registrierung/erfolg');
            } else if (registrationResponseStatus === 400 || registrationResponseStatus === 404) {
                setError('badgeNumber', { type: 'REGISTRATION', message: errorBadgeNumberNoCompanyFound });
            } else if (registrationResponseStatus === 409) {
                setError('badgeNumber', { type: 'REGISTRATION', message: errorCompanyForBadgeNumberAlreadyExists });
            }
        } else if (customerType === 'company' || customerType === 'projectpartner') {
            const { companyName, email, password } = data as CompanyValues;

            const registrationResponseStatus = await createAccount(
                {
                    name: companyName,
                    email,
                    password,
                    customerType,
                },
                redirectURL,
            );

            if (registrationResponseStatus === 201) history.push('/registrierung/erfolg');
        }
    };

    return (
        <FormProvider {...reactHookForm}>
            <div className="w-full mx-auto">
                <SignupForm
                    submit={submit}
                    externalCustomerType={externalCustomerType}
                    isEmailInputDisabled={isEmailInputDisabled}
                />
            </div>
        </FormProvider>
    );
};

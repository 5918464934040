import * as React from 'react';

export default (): React.ReactElement => (
    <span className="font-thin">
        Das Passwort muss aus <b className="font-bold">mindestens 10 Zeichen</b> bestehen, darf{' '}
        <b className="font-bold">den Firmennamen</b> oder <b className="font-bold">E-Mail nicht</b> enthalten und muss{' '}
        <b className="font-bold">3 der 4 Kategorien</b> enthalten:
        <br />
        <ol className="pl-5 list-disc">
            <li>Großbuchstaben</li>
            <li>Kleinbuchstaben</li>
            <li>Ziffern</li>
            <li>Sonderzeichen</li>
        </ol>
    </span>
);

import { useMsal } from '@azure/msal-react';
import { CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';

import useApi from '../hooks/useApi';
import { getCustomerType } from '../util/account';
import { getAccountManagement } from './api/getAccountManagement';
import { ChangeEmailContainer } from './change-email/ChangeEmailContainer';
import PasswordChangeContainer from './change-password/PasswordChangeContainer';
import { DeleteAccountContainer } from './delete-account/DeleteAccountContainer';

export const AccountManagement = (): React.ReactElement => {
    const getAccountManagementApi = useApi(getAccountManagement);

    const { accounts } = useMsal();
    const customerType = getCustomerType(accounts);
    const customerName = accounts[0].name;

    const [accountEmail, setAccountEmail] = useState<string>();
    const [hasAssignedProcess, setHasAssignedProcess] = useState<boolean>(true);
    const [accountManagementLoadingError, setAccountManagementLoadingError] = useState<boolean>(false);

    useEffect(() => {
        setAccountEmail(undefined);
        setAccountManagementLoadingError(false);

        getAccountManagementApi()
            .then((response) => {
                setAccountEmail(response.email);
                setHasAssignedProcess(response.hasAssignedProcess);
            })
            .catch((error) => {
                setAccountManagementLoadingError(true);
                console.error('Error during loading account email', error);
            });
    }, [getAccountManagementApi, accounts]);

    if (accountManagementLoadingError) {
        return (
            <div className="flex flex-col gap-y-6 py-10">
                <p>
                    Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </p>
            </div>
        );
    }

    if (!accountEmail) {
        return <CenteredLoadingSpinner />;
    }

    return (
        <>
            <PasswordChangeContainer
                accountEmail={accountEmail}
                customerType={customerType}
                customerName={customerName}
            />
            <div className="bg-dark-grey w-full h-px" />
            <ChangeEmailContainer accountEmail={accountEmail} />
            {!hasAssignedProcess && (
                <>
                    <div className="bg-dark-grey w-full h-px" />
                    <DeleteAccountContainer />
                </>
            )}
        </>
    );
};

import { useMsal } from '@azure/msal-react';
import { AxiosInstance } from 'axios';
import React from 'react';

import useAuthenticatedHttpClient from './useAuthenticatedHttpClient';

export type ApiParams<T> = {
    params: T;
    customerId: string;
    axios: AxiosInstance;
};

const useApi = <T, R>(fn: (params: ApiParams<T>) => Promise<R>) => {
    const { accounts } = useMsal();
    const axios = useAuthenticatedHttpClient();

    const call = React.useMemo(
        () => (params: T) => fn({ params, customerId: accounts[0]?.localAccountId, axios }),
        [accounts, axios, fn],
    );

    return call;
};

export default useApi;

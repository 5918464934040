import appConfig from '../../../app.config';
import { ApiParams } from '../../../hooks/useApi';

type AccountEmailUpdate = {
    email: string;
};

export async function updateEmail({ params, customerId, axios }: ApiParams<AccountEmailUpdate>) {
    await axios.patch<void>(`${appConfig.services.accountApi}/account/${customerId}/email`, params);
}

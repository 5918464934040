import { AuthenticatedContent, AuthorizedContent } from '@ten-netzkundenportal/ui-auth-utils';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { EnsureUnauthenticated } from './EnsureUnauthenticated';
import { AccountManagement } from './account-management/AccountManagement';
import { ConfirmEmailChange } from './account-management/confirm-email-change/ConfirmEmailChange';
import PasswordForgotContainer from './password-forgot';
import PasswordRecoveryContainer from './password-recovery';
import SignUpFormContainer from './signup';
import SuccessContainer from './signup/success/EmailRegistrationSuccessContainer';
import { VerificationContainer } from './verification/VerificationContainer';

const REGISTRATION_PATH = '/registrierung';
const ACCOUNT_PATH = '/account';

export const Root = (): React.ReactElement => (
    <Router>
        <Switch>
            <Route path={REGISTRATION_PATH}>
                <Route path={`${REGISTRATION_PATH}/verifizierung`}>
                    <EnsureUnauthenticated>
                        <VerificationContainer />
                    </EnsureUnauthenticated>
                </Route>
                <Route path={`${REGISTRATION_PATH}/erfolg`} component={SuccessContainer} />
                <Route exact path={`${REGISTRATION_PATH}/`} component={SignUpFormContainer} />
            </Route>
            <Route path={`${ACCOUNT_PATH}/passwort-zuruecksetzen`}>
                <EnsureUnauthenticated>
                    <PasswordRecoveryContainer />
                </EnsureUnauthenticated>
            </Route>
            <Route path={`${ACCOUNT_PATH}/passwort-vergessen`}>
                <EnsureUnauthenticated>
                    <PasswordForgotContainer />
                </EnsureUnauthenticated>
            </Route>
            <Route path={`${ACCOUNT_PATH}/accountverwaltung/e-mail-bestaetigung`} component={ConfirmEmailChange} />
            <Route exact path={[`${ACCOUNT_PATH}/accountverwaltung`, `${ACCOUNT_PATH}/passwort-aendern`]}>
                <AuthenticatedContent>
                    <AuthorizedContent acceptedCustomerTypes={['company', 'installer', 'private', 'projectpartner']}>
                        <AccountManagement />
                    </AuthorizedContent>
                </AuthenticatedContent>
            </Route>
        </Switch>
    </Router>
);

import axios from 'axios';

import appConfig from '../app.config';
import redirectAndExecuteMagicLogin from '../redirectAndExecuteMagicLogin';

export type Status = 'INITIAL' | 'PENDING' | 'REDIRECT' | 'ERROR';

async function api(token: string): Promise<Status> {
    if (token === undefined) {
        throw new Error('No token was provided!');
    }

    try {
        const res = await axios.put(`${appConfig.services.accountApi}/account/passwordless-login/${token}`, null, {
            validateStatus(status) {
                // Do not reject on Status codes 300 to 399 as axios does by default
                return status >= 200 && status < 400;
            },
        });

        if (res.status === 303) {
            const { idTokenHint, redirectURL } = res.data;
            await redirectAndExecuteMagicLogin(idTokenHint, redirectURL);
            return 'REDIRECT';
        }
        return 'ERROR';
    } catch (error) {
        return 'ERROR';
    }
}

export default api;

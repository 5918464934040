import { EmailInputField, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import forgotPassword from './api/forgotPassword';

export default (): React.ReactElement => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isValidating, isSubmitting },
    } = useForm<{ email: string }>({
        mode: 'onTouched',
        shouldUnregister: true,
    });

    const [isWaitingForPasswordRecoveryEmailInteraction, setIsWaitingForPasswordRecoveryEmailInteraction] =
        React.useState<boolean>(false);

    const submit = async (enteredData) => {
        try {
            await forgotPassword(enteredData.email);
            setIsWaitingForPasswordRecoveryEmailInteraction(true);
        } catch (error) {
            console.error('Failed to start recovery session', error);
        }
    };

    return (
        <div className="flex flex-col gap-y-6 py-10">
            <h3 className="text-medium font-bold">Passwort vergessen</h3>
            {isWaitingForPasswordRecoveryEmailInteraction ? (
                <>
                    <span>
                        Vielen Dank! Es wird nun eine E-Mail an Ihre angegebene E-Mail-Adresse geschickt mit einem Link
                        zum Zurücksetzen Ihres Passworts. Klicken Sie bitte auf diesen Link - Sie kommen dann auf eine
                        Seite, wo Sie ein neues Passwort für Ihren Netzkundenportal-Zugang vergeben können.
                    </span>
                    <span className="text-small flex w-full">
                        <b>Hinweis:</b>&nbsp;Bitte überprüfen Sie Ihren Spam-Ordner, falls Sie nach einigen Minuten noch
                        keine E-Mail erhalten haben.
                    </span>
                </>
            ) : (
                <>
                    <span>
                        Wenn Sie Ihr Passwort vergessen haben, können Sie hier einen Link zum Zurücksetzen des Passworts
                        anfordern.
                    </span>
                    <div className="w-1/3 pr-3">
                        <EmailInputField
                            label="E-Mail-Adresse"
                            id="email"
                            register={register}
                            displayErrorMessage={errors?.email?.message}
                            enableAutoComplete
                        />
                    </div>

                    <div className="flex justify-end gap-x-6 pt-24">
                        <LoadingSpinnerButton
                            loading={isSubmitting || isValidating}
                            type="primary"
                            disabled={!isValid || isValidating}
                            label="Weiter"
                            loadingTitle="Der Link zum Zurücksetzen wird versendet."
                            onClick={handleSubmit(submit)}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

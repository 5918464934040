import { AxiosResponse } from 'axios';

import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';

type AccountManagementResponse = {
    email: string;
    hasAssignedProcess: boolean;
};

export const getAccountManagement = async ({
    customerId,
    axios,
}: ApiParams<void>): Promise<AccountManagementResponse> => {
    const response: AxiosResponse<AccountManagementResponse> = await axios.get(
        `${appConfig.services.accountApi}/account/${customerId}/account-management`,
    );

    if (response.status !== 200) {
        throw new Error('An error occurred while getting the account info');
    }

    return response.data;
};

import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@ten-netzkundenportal/ui-auth-utils';
import {
    Button,
    CenteredLoadingSpinner,
    Infobox,
    LinkButton,
    LoadingSpinner,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import appConfig from '../app.config';
import redirectAndExecuteMagicLogin from '../redirectAndExecuteMagicLogin';
import delay from '../util/delay';
import api, { Status } from './api';

interface State {
    loginStatus: Status;
}
type SetState = (state: State) => void;

interface VerificationQueryParams {
    b64Token: string;
}

const login = async () => {
    const msalInstance = new PublicClientApplication(msalConfig(appConfig));

    await msalInstance.initialize();
    await msalInstance.handleRedirectPromise();

    const accounts = msalInstance.getAllAccounts();
    if (accounts?.length === 0) {
        await msalInstance.loginRedirect();
    }
    // react router is navigating to the configured base path of the router and not to /
    window.location.href = '/';
};

async function authenticate(setState: SetState, b64Token: string) {
    const res = await api(b64Token);
    setState({ loginStatus: res.status });

    if (res.status === 'REDIRECT') {
        if (res.data === undefined) {
            throw Error('Illegal State: response should contain a token and a redirect url');
        }

        const { idTokenHint, redirectURL } = res.data;
        await delay();
        await redirectAndExecuteMagicLogin(idTokenHint, redirectURL);
    }
}

export default (): React.ReactElement => {
    const b64Token = new URLSearchParams(useLocation<VerificationQueryParams>().search).get('token');
    const [state, setState] = useState<State>({ loginStatus: 'INITIAL' });

    useEffect(() => {
        if (state.loginStatus === 'INITIAL') {
            authenticate(setState, b64Token);
        }
    }, [b64Token, state, setState]);

    switch (state.loginStatus) {
        case 'DONE':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Vielen Dank für die Bestätigung Ihrer Registrierung!"
                        normalText="Bitte melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <Button onClick={login} label="Zur Anmeldung" />
                    </div>
                </div>
            );
        case 'REDIRECT':
            return (
                <div className="w-full h-full py-10">
                    <div className="h-full grid md:grid-rows-5">
                        <div className="row-start-2">
                            <Infobox
                                boldText="Vielen Dank für die Aktivierung Ihres Netzkundenportal-Accounts!"
                                normalText="Wir leiten Sie zu Ihrem Antrag zurück. Dies kann einen kurzen Moment dauern."
                            />
                        </div>
                        <div className="w-20 h-20 row-start-3 justify-self-center">
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            );
        case 'EXPIRED':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Leider ist der Aktivierungslink für Ihren Account nicht mehr gültig."
                        normalText="Bitte führen Sie die Registrierung erneut aus."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <LinkButton href="/registrierung" label="Zur Registrierung" />
                    </div>
                </div>
            );
        case 'ERROR':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Leider ist bei der Registrierung ein technischer Fehler aufgetreten."
                        normalText="Bitte führen Sie die Registrierung erneut aus."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <LinkButton href="/registrierung" label="Zur Registrierung" />
                    </div>
                </div>
            );
        case 'PENDING':
        case 'INITIAL':
            return <CenteredLoadingSpinner />;
        default:
            return <div />;
    }
};

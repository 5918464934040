import { ErrorBoundary } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import rootComponent from './root.component';

const lifecycles = singleSpaReact({
    React,
    ReactDOMClient,
    rootComponent,
    errorBoundary: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;

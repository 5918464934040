import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React, { useEffect } from 'react';

type EnsureUnauthenticatedProps = {
    children: React.ReactElement;
};

export const EnsureUnauthenticated = ({ children }: EnsureUnauthenticatedProps): React.ReactElement => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            instance.logoutRedirect({ postLogoutRedirectUri: window.location.href }).catch((error) => {
                console.error('Logout failed', error);
            });
        }
    }, [instance, isAuthenticated]);

    if (isAuthenticated) {
        return <CenteredLoadingSpinner />;
    }

    return children;
};

import { Infobox, LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import api, { Status } from './api';

interface PasswordRecoveryQueryParams {
    b64Token: string;
}

export default (): React.ReactElement => {
    const b64Token = new URLSearchParams(useLocation<PasswordRecoveryQueryParams>().search).get('token');
    const [status, setStatus] = useState<Status>('INITIAL');

    if (status === 'INITIAL') {
        setStatus('PENDING');
        api(b64Token)
            .then((responseStatus) => {
                setStatus(responseStatus);
            })
            .catch(() => setStatus('ERROR'));
    }

    switch (status) {
        case 'ERROR':
            return (
                <div className="grid grid-flow-row justify-center">
                    <Infobox
                        boldText="Leider ist der Link zum Zurücksetzen Ihres Passworts nicht mehr gültig."
                        normalText="Bitte fordern Sie einen neuen Link an."
                    />
                </div>
            );
        case 'PENDING':
        case 'INITIAL':
        case 'REDIRECT':
        default:
            return (
                <div className="w-full flex flex-col items-center">
                    <div className="flex flex-row items-center" style={{ minHeight: '85vh' }}>
                        <div className="w-20 h-20">
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            );
    }
};

import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import axios from 'axios';

import appConfig from '../app.config';

interface User {
    email: string;
    password: string;
    customerType: CustomerType;
}

interface Customer extends User {
    firstName: string;
    lastName: string;
    customerType: 'private';
}

interface Company extends User {
    name: string;
    customerType: 'company';
}
interface ProjectPartner extends User {
    name: string;
    customerType: 'projectpartner';
}

interface Installer extends User {
    name: string;
    customerType: 'installer';
    badgeNumber: string;
}

export const createAccount = async (
    reqData: Customer | Company | Installer | ProjectPartner,
    redirectURL?: string,
): Promise<number> => {
    try {
        const res = await axios.post(`${appConfig.services.accountApi}/account/registration`, {
            customer: {
                ...reqData,
            },
            redirectURL: redirectURL ?? undefined,
        });
        return res.status;
    } catch (error) {
        return error.response.status;
    }
};

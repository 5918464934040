import {
    AuthenticatedContent,
    AuthorizedContent,
    InitializingMsalProvider,
    msalConfig,
} from '@ten-netzkundenportal/ui-auth-utils';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AccountManagement } from './account-management/AccountManagement';
import { ConfirmEmailChange } from './account-management/confirm-email-change/ConfirmEmailChange';
import appConfig from './app.config';
import PasswordForgotContainer from './password-forgot';
import PasswordRecoveryContainer from './password-recovery';
import SignUpFormContainer from './signup';
import SuccessContainer from './signup/success/EmailRegistrationSuccessContainer';
import VerificationContainer from './verification';

const REGISTRATION_PATH = '/registrierung';
const ACCOUNT_PATH = '/account';

export default (): React.ReactElement => (
    <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
            <Route path={REGISTRATION_PATH}>
                <Route path={`${REGISTRATION_PATH}/verifizierung`} component={VerificationContainer} />
                <Route path={`${REGISTRATION_PATH}/erfolg`} component={SuccessContainer} />
                <Route exact path={`${REGISTRATION_PATH}/`} component={SignUpFormContainer} />
            </Route>
            <Route path={`${ACCOUNT_PATH}/passwort-zuruecksetzen`} component={PasswordRecoveryContainer} />
            <Route path={`${ACCOUNT_PATH}/passwort-vergessen`} component={PasswordForgotContainer} />
            <InitializingMsalProvider msalConfig={msalConfig(appConfig)}>
                <Route path={`${ACCOUNT_PATH}/accountverwaltung/e-mail-bestaetigung`} component={ConfirmEmailChange} />
                <Route exact path={[`${ACCOUNT_PATH}/accountverwaltung`, `${ACCOUNT_PATH}/passwort-aendern`]}>
                    <AuthenticatedContent>
                        <AuthorizedContent
                            acceptedCustomerTypes={['company', 'installer', 'private', 'projectpartner']}
                        >
                            <AccountManagement />
                        </AuthorizedContent>
                    </AuthenticatedContent>
                </Route>
            </InitializingMsalProvider>
        </Switch>
    </Router>
);

import { useMsal } from '@azure/msal-react';
import { CenteredLoadingSpinner, Infobox } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import redirectAndExecuteMagicLogin from '../util/redirectAndExecuteMagicLogin';
import api, { Status } from './api';

interface PasswordRecoveryQueryParams {
    b64Token: string;
}

export default (): React.ReactElement => {
    const b64Token = new URLSearchParams(useLocation<PasswordRecoveryQueryParams>().search).get('token');
    const [status, setStatus] = useState<Status>('INITIAL');

    const { instance } = useMsal();

    useEffect(() => {
        if (status === 'INITIAL') {
            setStatus('PENDING');
            api(b64Token)
                .then(async (response) => {
                    setStatus(response.status);
                    if (response.status === 'REDIRECT') {
                        const { idTokenHint, redirectURL } = response.data;
                        await redirectAndExecuteMagicLogin(instance, idTokenHint, redirectURL);
                    }
                })
                .catch((error) => {
                    setStatus('ERROR');
                    console.error('Password recovery link validation failed', error);
                });
        }
    }, [b64Token, instance, status]);

    switch (status) {
        case 'ERROR':
            return (
                <div className="grid grid-flow-row justify-center">
                    <Infobox
                        boldText="Leider ist der Link zum Zurücksetzen Ihres Passworts nicht mehr gültig."
                        normalText="Bitte fordern Sie einen neuen Link an."
                    />
                </div>
            );
        case 'PENDING':
        case 'INITIAL':
        case 'REDIRECT':
        default:
            return <CenteredLoadingSpinner />;
    }
};

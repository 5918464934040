import { useMsal } from '@azure/msal-react';
import {
    Button,
    CenteredLoadingSpinner,
    Infobox,
    LinkButton,
    LoadingSpinner,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import delay from '../util/delay';
import redirectAndExecuteMagicLogin from '../util/redirectAndExecuteMagicLogin';
import api, { Status } from './api';

interface State {
    loginStatus: Status;
}

interface VerificationQueryParams {
    b64Token: string;
}

export const VerificationContainer = (): React.ReactElement => {
    const b64Token = new URLSearchParams(useLocation<VerificationQueryParams>().search).get('token');
    const [state, setState] = useState<State>({ loginStatus: 'INITIAL' });

    const { instance } = useMsal();

    useEffect(() => {
        if (state.loginStatus === 'INITIAL') {
            setState({ loginStatus: 'PENDING' });

            api(b64Token)
                .then(async (response) => {
                    setState({ loginStatus: response.status });

                    if (response.status === 'REDIRECT') {
                        const { idTokenHint, redirectURL } = response.data;
                        await delay();
                        await redirectAndExecuteMagicLogin(instance, idTokenHint, redirectURL);
                    }
                })
                .catch((error) => {
                    console.error('Token verification failed', error);
                    setState({ loginStatus: 'ERROR' });
                });
        }
    }, [b64Token, instance, state]);

    const login = () => {
        instance.loginRedirect().catch((error) => {
            console.error('Login redirect failed', error);
        });
    };

    switch (state.loginStatus) {
        case 'DONE':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Vielen Dank für die Bestätigung Ihrer Registrierung!"
                        normalText="Bitte melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <Button onClick={login} label="Zur Anmeldung" />
                    </div>
                </div>
            );
        case 'REDIRECT':
            return (
                <div className="w-full h-full py-10">
                    <div className="h-full grid md:grid-rows-5">
                        <div className="row-start-2">
                            <Infobox
                                boldText="Vielen Dank für die Aktivierung Ihres Netzkundenportal-Accounts!"
                                normalText="Wir leiten Sie zu Ihrem Antrag zurück. Dies kann einen kurzen Moment dauern."
                            />
                        </div>
                        <div className="w-20 h-20 row-start-3 justify-self-center">
                            <LoadingSpinner />
                        </div>
                    </div>
                </div>
            );
        case 'EXPIRED':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Leider ist der Aktivierungslink für Ihren Account nicht mehr gültig."
                        normalText="Bitte führen Sie die Registrierung erneut aus."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <LinkButton href="/registrierung" label="Zur Registrierung" />
                    </div>
                </div>
            );
        case 'ERROR':
            return (
                <div className="grid grid-flow-row justify-center py-10">
                    <Infobox
                        boldText="Leider ist bei der Registrierung ein technischer Fehler aufgetreten."
                        normalText="Bitte führen Sie die Registrierung erneut aus."
                    />
                    <div className="flex justify-center w-auto text-center">
                        <LinkButton href="/registrierung" label="Zur Registrierung" />
                    </div>
                </div>
            );
        case 'PENDING':
        case 'INITIAL':
        default:
            return <CenteredLoadingSpinner />;
    }
};

import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import { Button, CustomerTypeSelection, Form, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import {
    FormValues,
    SignUpFormCompany,
    SignUpFormInstaller,
    SignUpFormPrivateCustomer,
} from '@ten-netzkundenportal/ui-registration';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

export type SignupProps = {
    externalCustomerType?: CustomerType;
    isEmailInputDisabled: boolean;
    submit: (customerType: CustomerType) => (data: FormValues) => Promise<void>;
};

export default ({ externalCustomerType, submit, isEmailInputDisabled }: SignupProps): React.ReactElement => {
    const [customerType, setCustomerType] = React.useState<CustomerType>(externalCustomerType);

    const {
        formState: { isValid, isValidating, isSubmitting },
        clearErrors,
        handleSubmit,
    } = useFormContext<FormValues>();

    const history = useHistory();

    React.useEffect(() => {
        if (externalCustomerType) {
            setCustomerType(externalCustomerType);
        }
    }, [externalCustomerType]);

    return (
        <Form title="Registrierung">
            {!externalCustomerType && (
                <CustomerTypeSelection
                    customerType={customerType}
                    onChange={(customer: CustomerType) => {
                        setCustomerType(customer);
                        if (customer === 'installer') {
                            clearErrors('badgeNumber');
                        }
                    }}
                />
            )}

            {customerType === 'installer' && (
                <SignUpFormInstaller requireEmailConfirmation={false} isEmailInputDisabled={isEmailInputDisabled} />
            )}
            {customerType === 'projectpartner' && (
                <SignUpFormCompany requireEmailConfirmation={false} isEmailInputDisabled={isEmailInputDisabled} />
            )}
            {customerType === 'private' && (
                <SignUpFormPrivateCustomer
                    requireEmailConfirmation={false}
                    isEmailInputDisabled={isEmailInputDisabled}
                />
            )}
            {customerType === 'company' && (
                <SignUpFormCompany requireEmailConfirmation={false} isEmailInputDisabled={isEmailInputDisabled} />
            )}
            <div className="flex justify-between mt-12">
                <Button
                    type="secondary"
                    label="Zurück"
                    onClick={() => {
                        if (history.length > 1) {
                            history.goBack();
                        } else {
                            history.push('/');
                        }
                    }}
                />
                <LoadingSpinnerButton
                    type="primary"
                    disabled={!customerType || !isValid || isValidating || isSubmitting}
                    label="Registrieren"
                    onClick={handleSubmit(submit(customerType))}
                    loading={isSubmitting || isValidating}
                />
            </div>
        </Form>
    );
};

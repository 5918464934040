import axios from 'axios';

import appConfig from '../app.config';

export type Status = 'INITIAL' | 'PENDING' | 'REDIRECT' | 'EXPIRED' | 'ERROR' | 'DONE';

async function api(token: string): Promise<{ status: Status; data?: { idTokenHint: string; redirectURL: string } }> {
    if (token === undefined) {
        throw new Error('No token was provided!');
    }

    try {
        const res = await axios.put(`${appConfig.services.accountApi}/account/registration/${token}`, null, {
            validateStatus(status) {
                // Do not reject on status codes 300 to 399 as axios only accepts codes 200 to 299 by default
                return status >= 200 && status < 400;
            },
        });

        if (res.status === 303) {
            const { idTokenHint, redirectURL } = res.data;
            return { status: 'REDIRECT', data: { idTokenHint, redirectURL } };
        }

        const status = res.status === 204 ? 'DONE' : 'ERROR';
        return { status };
    } catch (error) {
        const { status } = error.response;
        if (status === 404 || status === 410) {
            return { status: 'EXPIRED' };
        }
        return { status: 'ERROR' };
    }
}

export default api;

import { AxiosResponse } from 'axios';

import appConfig from '../../../app.config';
import { ApiParams } from '../../../hooks/useApi';

type AccountUpdate = {
    password: string;
};

export default async ({ params, customerId, axios }: ApiParams<AccountUpdate>) => {
    try {
        const accountPatchResponse: AxiosResponse = await axios.patch<AccountUpdate>(
            `${appConfig.services.accountApi}/account/${customerId}`,
            {
                password: params.password,
            },
        );
        return accountPatchResponse.status;
    } catch (error) {
        return error.response.status;
    }
};

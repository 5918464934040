import axios, { AxiosResponse } from 'axios';

import appConfig from '../../app.config';

export default async (email: string): Promise<void> => {
    const passwordRecoverySessionResponse: AxiosResponse = await axios.post(
        `${appConfig.services.accountApi}/account/password-recovery-session`,
        {
            email,
        },
    );
    if (passwordRecoverySessionResponse.status !== 204) {
        throw Error(`Received unexpected status from backend call: ${passwordRecoverySessionResponse.status}`);
    }
};

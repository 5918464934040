import { EmailInputField, ErrorBox, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import { getRegistrationStatus } from '@ten-netzkundenportal/ui-registration';
import { GenericAbortSignal } from 'axios';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';

import useApi from '../../hooks/useApi';
import { updateEmail } from './api/updateEmail';

type ChangeEmailContainerProps = {
    accountEmail: string;
};

type FormData = {
    email: string;
};

export const ChangeEmailContainer = ({ accountEmail }: ChangeEmailContainerProps): ReactElement => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isValidating, isSubmitting },
    } = useForm<FormData>({
        mode: 'onTouched',
        shouldUnregister: true,
    });

    const updateEmailApi = useApi(updateEmail);

    const [showNextStepInfo, setShowNextStepInfo] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    const submit = async (formData: FormData) => {
        setShowError(false);

        try {
            await updateEmailApi({ email: formData.email });
            setShowNextStepInfo(true);
        } catch (error) {
            console.error('Failed to change email', error);
            setShowError(true);
        }
    };

    const additionalEmailValidation = async (email: string, signal: GenericAbortSignal) => {
        if (email.toLowerCase() === accountEmail.toLowerCase()) {
            return (
                'Die eingegebene E-Mail-Adresse wird aktuell bereits für Ihren Account verwendet. ' +
                'Bitte geben Sie eine abweichende E-Mail-Adresse ein.'
            );
        }

        const response = await getRegistrationStatus(email, signal);

        if (response.registrationStatus !== 'notRegistered') {
            return 'Es gibt bereits einen Account zu dieser E-Mail-Adresse. Bitte geben Sie eine andere E-Mail-Adresse an.';
        }

        return undefined;
    };

    return (
        <div className="flex flex-col gap-y-6 py-10">
            <h3 className="text-medium font-bold">E-Mail-Adresse ändern</h3>
            {showNextStepInfo ? (
                <>
                    <span>
                        Vielen Dank! Es wird nun eine E-Mail mit einem Bestätigungslink an Ihre neue E-Mail-Adresse
                        geschickt. Um die Änderung Ihrer E-Mail-Adresse abzuschließen, klicken Sie bitte auf den Link
                        aus dieser E-Mail.
                    </span>
                    <span className="text-small flex w-full">
                        <b>Hinweis:</b>&nbsp;Bitte überprüfen Sie Ihren Spam-Ordner, falls Sie nach einigen Minuten noch
                        keine E-Mail erhalten haben.
                    </span>
                </>
            ) : (
                <>
                    <span>Nachfolgend können Sie Ihre E-Mail-Adresse für Ihren Netzkundenportal-Zugang ändern.</span>
                    <div className="w-1/3 pr-3">
                        <EmailInputField
                            id="email"
                            label="Neue E-Mail-Adresse"
                            register={register}
                            displayErrorMessage={errors.email?.message}
                            additionalEmailValidation={additionalEmailValidation}
                            enableAutoComplete
                        />
                    </div>

                    <div className="flex justify-end gap-x-6 pt-8">
                        <LoadingSpinnerButton
                            loading={isSubmitting || isValidating}
                            type="primary"
                            disabled={!isValid || isValidating}
                            label="Speichern"
                            loadingTitle="Die neue E-Mail-Adresse wird gespeichert."
                            onClick={handleSubmit(submit)}
                        />
                    </div>

                    {showError && <ErrorBox position="!place-self-end" onClick={() => setShowError(false)} />}
                </>
            )}
        </div>
    );
};

import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import { InfoI, LoadingSpinnerButton, PasswordInputField } from '@ten-netzkundenportal/ui-components';
import { validatePassword } from '@ten-netzkundenportal/ui-registration';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import PasswordDescriptionCompany from '../../components/PasswordDescriptionCompany';
import PasswordDescriptionPrivate from '../../components/PasswordDescriptionPrivate';
import { errorConfirmPassword, errorConfirmPasswordNull, errorPassword } from '../../components/messages';
import useApi from '../../hooks/useApi';
import changePassword from './api/changePassword';

type PasswordChange = {
    newPassword: string;
    confirmNewPassword: string;
};

type PasswordChangeContainerProps = {
    accountEmail: string;
    customerType: CustomerType;
    customerName: string;
};

export default ({ accountEmail, customerType, customerName }: PasswordChangeContainerProps): React.ReactElement => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
        watch,
        setError,
        clearErrors,
    } = useForm<PasswordChange>({
        mode: 'onTouched',
        shouldUnregister: true,
    });
    const history = useHistory();
    const changePasswordApi = useApi(changePassword);

    const submit = async (enteredData) => {
        const response = await changePasswordApi({
            password: enteredData.newPassword,
        });
        if (response === 204) {
            history.push('/uebersicht');
        } else if (response === 400) {
            setError('newPassword', { type: 'INVALID_PASSWORD', message: errorPassword });
        } else {
            console.error('Failed to change password', response);
        }
    };

    const password = watch('newPassword');

    React.useEffect(() => {
        if (errors.newPassword?.type === 'INVALID_PASSWORD') {
            clearErrors('newPassword');
        }
        // eslint-disable-next-line
    }, [password]);

    const validateConfirmPassword = (value: string) => password === value;

    const validateEnteredPassword = (value: string) =>
        validatePassword(value, {
            name: customerName,
            email: accountEmail,
        });

    return (
        <div className="flex flex-col gap-y-6 py-10">
            <h3 className="text-medium font-bold">Passwort ändern</h3>

            <span>
                Nachfolgend können Sie Ihr Passwort für Ihren Netzkundenportal-Zugang ändern. Bitte geben Sie Ihr neues
                Passwort an und bestätigen Sie es durch eine erneute Eingabe. Bitte beachten Sie dabei die
                Passwort-Anforderungen.
            </span>
            <div className="flex flex-col gap-y-5 w-1/3">
                <PasswordInputField
                    label="Passwort"
                    name="password"
                    labelIcon={
                        <InfoI
                            size="30rem"
                            text={
                                customerType === 'private' ? (
                                    <PasswordDescriptionPrivate />
                                ) : (
                                    <PasswordDescriptionCompany />
                                )
                            }
                        />
                    }
                    displayErrorMessage={errors.newPassword && errorPassword}
                    {...register('newPassword', {
                        required: true,
                        validate: validateEnteredPassword,
                    })}
                />
                <PasswordInputField
                    label="Passwort bestätigen"
                    name="confirmPassword"
                    displayErrorMessage={
                        errors.confirmNewPassword &&
                        (errors.confirmNewPassword.type === 'validate'
                            ? errorConfirmPassword
                            : errors.confirmNewPassword.message)
                    }
                    {...register('confirmNewPassword', {
                        required: errorConfirmPasswordNull,
                        validate: validateConfirmPassword,
                        shouldUnregister: true,
                    })}
                />
            </div>

            <div className="flex justify-end pt-8">
                <LoadingSpinnerButton
                    loading={isSubmitting}
                    type="primary"
                    disabled={!isValid}
                    label="Speichern"
                    loadingTitle="Das Passwort wird gespeichert."
                    onClick={handleSubmit(submit)}
                />
            </div>
        </div>
    );
};
